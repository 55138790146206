import React from 'react';
import {Input} from '@flixbus/honeycomb-react';
import {isNil} from 'ramda';
import PropTypes from 'prop-types';

const PlanrPartnerName = ({index, productionCostBus}) => {
  PlanrPartnerName.prototype = {
    index: PropTypes.string.isRequired,
    productionCostBus: PropTypes.object
  };

  const getPlanrPartnerName = () => {
    let planrPartnerName;

    if (productionCostBus) {
      if (!isNil(productionCostBus.busPartnerName)) {
        planrPartnerName = productionCostBus.busPartnerName;
      } else {
        planrPartnerName = 'N/A';
      }
    } else {
      planrPartnerName = '';
    }
    return planrPartnerName;
  };

  return (
    <Input
      id={`${index}-planr-partner-name`}
      name="planr-partner-name"
      label="PlanR partner name"
      value={getPlanrPartnerName() || ''}
      readOnly
      extraClasses={`info-only`}
    />
  );
};

export default React.memo(PlanrPartnerName);
