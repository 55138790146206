import React from 'react';
import {Button, Fieldset, Grid, GridCol} from '@flixbus/honeycomb-react';
import {Icon, IconCopy} from '@flixbus/honeycomb-icons-react';
import {useDispatch, useSelector} from 'react-redux';
import {
  copyValuesToAll,
  copyValuesToSameVehicle,
  copyValuesToSameVehicleAndScheduleSize,
  validateBusScheduleValues
} from '../../../store/slice';
import {isNotNil} from 'ramda-adjunct';
import {useToast} from '../../../../Common/toasts-container';
import {confirm} from '../../../../Common/Confirm';
import {CalculationUserJourneyMode} from 'Pages/ProductionCost/store/types';

const CopyVehicle = ({lineVehicleId, scheduleSize}) => {
  const {addToast} = useToast();
  const dispatch = useDispatch();

  const calculationUserJourneyMode = useSelector(
    state => state?.productionCost.calculationUserJourneyMode
  );

  const successToastMessage = message =>
    addToast({
      type: 'success',
      content: message
    });

  const onClickCopyToSameVehicle = () => {
    dispatch(copyValuesToSameVehicle(lineVehicleId));
    dispatch(validateBusScheduleValues());
    successToastMessage(
      `Vehicle values have been copied to same vehicles successfully`
    );
  };
  const onClickCopyToSameVehicleAndScheduleSize = () => {
    dispatch(copyValuesToSameVehicleAndScheduleSize(lineVehicleId));
    dispatch(validateBusScheduleValues());
    successToastMessage(
      `Vehicle values have been copied to schedules with size ${scheduleSize} successfully`
    );
  };
  const onClickCopyToAll = async () => {
    if (
      await confirm({
        PopupIcon: IconCopy,
        title: 'Copy this vehicle values to all vehicles ?',
        message: `Please be cautious, all values will be overwritten`
      })
    ) {
      dispatch(copyValuesToAll(lineVehicleId));
      dispatch(validateBusScheduleValues());
      successToastMessage(
        `Vehicle values have been copied to all successfully`
      );
    }
  };
  return (
    <Grid justify="right">
      <GridCol size={4}>
        <Fieldset horizontal>
          <Button appearance="tertiary" onClick={onClickCopyToSameVehicle}>
            <Icon InlineIcon={IconCopy} aria-hidden="true" />
            Copy to same vehicle
          </Button>
          {isNotNil(scheduleSize) && (
            <Button
              appearance="tertiary"
              onClick={onClickCopyToSameVehicleAndScheduleSize}
            >
              <Icon InlineIcon={IconCopy} aria-hidden="true" />
              Copy to same vehicle of all {scheduleSize} schedules
            </Button>
          )}
          {CalculationUserJourneyMode.EditCalculation !==
            calculationUserJourneyMode && (
            <Button appearance="tertiary" onClick={onClickCopyToAll}>
              <Icon InlineIcon={IconCopy} aria-hidden="true" />
              Copy to all
            </Button>
          )}
        </Fieldset>
      </GridCol>
    </Grid>
  );
};

export default React.memo(CopyVehicle);
