import {useState} from 'react';
import {ProductionCostError, ProductionCostErrorResult} from '../../types';
import {useDeepCompareEffect} from 'react-use';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';

export const useProductionCostErrors = (
  providedValuesEntryId?: string,
  vehicleId?: string
): ProductionCostErrorResult => {
  const productionCostErrors = useAppSelector(
    ({productionCost}: any) => productionCost?.errors?.productionCostCalculation
  );

  const initialProductionCostErrorResult = new ProductionCostErrorResult();

  const [productionCostErrorResult, setProductionCostErrorResult] =
    useState<ProductionCostErrorResult>(initialProductionCostErrorResult);

  const isErrorFound = productionCostErrors?.length > 0;
  let isVehicleErrorFound = false;
  const getProductionCostErrorResult = () => {
    if (isErrorFound) {
      let productionCostErrorsFiltered: ProductionCostError[];
      if (providedValuesEntryId) {
        productionCostErrorsFiltered = productionCostErrors.filter(
          ({extensions}: ProductionCostError) =>
            [
              'InvalidProductionCostBusScheduleProvidedValueError',
              'InvalidProductionCostBusScheduleCostFactorError'
            ].includes(extensions?.errorType) &&
            extensions?.providedValuesEntryId === providedValuesEntryId
        );

        const isMultipleBusPartnersErrorFound = productionCostErrors.some(
          (error: ProductionCostError) =>
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            error?.extensions?.errorArguments?.includes(vehicleId!)
        );
        isVehicleErrorFound =
          productionCostErrorsFiltered?.length > 0 ||
          isMultipleBusPartnersErrorFound;
      } else {
        productionCostErrorsFiltered = productionCostErrors.filter(
          ({extensions}: ProductionCostError) =>
            ![
              'InvalidProductionCostBusScheduleProvidedValueError',
              'InvalidProductionCostBusScheduleCostFactorError'
            ].includes(extensions?.errorType)
        );
      }
      return new ProductionCostErrorResult(
        isErrorFound,
        isVehicleErrorFound,
        productionCostErrors.length,
        productionCostErrorsFiltered
      );
    } else {
      return initialProductionCostErrorResult;
    }
  };

  useDeepCompareEffect(() => {
    setProductionCostErrorResult(getProductionCostErrorResult());
  }, [productionCostErrors || {}]);

  return productionCostErrorResult;
};
