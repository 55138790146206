import {isNilOrEmpty} from 'ramda-adjunct';
import {
  ProductionCostBusSchedule,
  ProductionCostBusScheduleProvidedValueEntry
} from './types';

export const copyVehicleToBusSchedules = (
  id: string,
  vehicleCriteriaFields: (keyof ProductionCostBusScheduleProvidedValueEntry)[],
  scheduleCriteriaFields: (keyof ProductionCostBusSchedule)[],
  busScheduleValues: ProductionCostBusSchedule[]
) => {
  const vehicleWithScheduleToBeCopied = (() => {
    for (const busSchedule of busScheduleValues) {
      const vehicle = busSchedule.busScheduleProvidedValuesEntries.find(
        (vehicle: ProductionCostBusScheduleProvidedValueEntry) =>
          id === vehicle.id
      );
      if (vehicle) return {vehicle, busSchedule};
    }
    return {
      vehicle: {} as ProductionCostBusScheduleProvidedValueEntry,
      busSchedule: {} as ProductionCostBusSchedule
    };
  })();

  const copyToVehicle = (
    vehicle: ProductionCostBusScheduleProvidedValueEntry,
    busSchedule: ProductionCostBusSchedule
  ): ProductionCostBusScheduleProvidedValueEntry => {
    const appliesForVehicle =
      isNilOrEmpty(vehicleCriteriaFields) ||
      vehicleCriteriaFields.every(
        field => vehicleWithScheduleToBeCopied.vehicle[field] === vehicle[field]
      );

    const appliesForSchedule =
      isNilOrEmpty(scheduleCriteriaFields) ||
      scheduleCriteriaFields.every(
        field =>
          vehicleWithScheduleToBeCopied.busSchedule[field] ===
          busSchedule[field]
      );

    if (appliesForVehicle && appliesForSchedule) {
      return {
        ...vehicleWithScheduleToBeCopied.vehicle,
        id: vehicle.id,
        vehicleId: vehicle.vehicleId
      };
    }
    return vehicle;
  };

  return busScheduleValues.map(busSchedule => {
    const busScheduleProvidedValuesEntries =
      busSchedule.busScheduleProvidedValuesEntries.map(
        (vehicle: ProductionCostBusScheduleProvidedValueEntry) => {
          return copyToVehicle(vehicle, busSchedule);
        }
      );

    return {
      ...busSchedule,
      busScheduleProvidedValuesEntries
    };
  });
};
