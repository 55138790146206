import React from 'react';
import {countriesList} from '../../../../Countries/constant/countriesList';
import {filter, isNil, pluck} from 'ramda';
import {Icon, IconAttention} from '@flixbus/honeycomb-icons-react';
import {fetchPartnerAndCountryDriverCost} from '../../../store/thunks';

export const getTimeZones = (countries, countryId) => {
  if (isNil(countries)) {
    return [];
  }

  const country = countries.find(country => country.id === countryId);

  if (isNil(country)) {
    return [];
  }

  const countryWithTimezone = countriesList.find(countryWithTimezone =>
    country.name.includes(countryWithTimezone.name)
  );

  if (isNil(countryWithTimezone)) {
    return getDefaultTimeZones(countries);
  }

  return countryWithTimezone.timezones.sort();
};

const getDefaultTimeZones = countries => {
  const doesCountryExist = countryWithTimeZone =>
    countries.some(country => country.name === countryWithTimeZone.name);
  const getTimeZones = pluck('timezones');
  return getTimeZones(filter(doesCountryExist, countriesList)).flat().sort();
};

export const getInlineIconProps = setActiveTooltip => {
  return {
    'aria-label': 'Tooltip Info',
    onMouseOver: () => setActiveTooltip(true),
    onMouseOut: () => setActiveTooltip(false),
    onBlur: () => setActiveTooltip(false),
    style: {
      cursor: 'pointer',
      pointerEvents: 'auto',
      background: 'none',
      outline: 'none'
    }
  };
};

const getInfoIcon = (id, setActiveTooltip) => {
  return (
    <IconAttention
      onMouseOver={() => setActiveTooltip(true)}
      onMouseOut={() => setActiveTooltip(false)}
      onBlur={() => setActiveTooltip(false)}
      role="img"
      style={{
        cursor: 'pointer',
        pointerEvents: 'auto'
      }}
    />
  );
};

export const getSelectInfoIcon = (id, setActiveTooltip) => (
  <Icon
    extraClasses="select-info-icon"
    size={4}
    InlineIcon={() => getInfoIcon(id, setActiveTooltip)}
  />
);

export const getAccommodationCostPerNight = (
  busPartnerId,
  countryId,
  productionCostYear,
  busPartnerAndCountryDriverCostEntry,
  dispatch
) => {
  const busPartnerAndCountryDriverCostEntryByYear =
    busPartnerAndCountryDriverCostEntry[
      busPartnerId + countryId + productionCostYear
    ];

  if (!busPartnerAndCountryDriverCostEntryByYear) {
    callFetchPartnerAndCountryDriverCost(
      busPartnerId,
      countryId,
      productionCostYear,
      dispatch
    );
    return null;
  }

  const {busPartnerDriverCostEntry, countryDriverCostEntry} =
    busPartnerAndCountryDriverCostEntryByYear;
  return (
    busPartnerDriverCostEntry.accommodationCostPerNight ??
    countryDriverCostEntry.accommodationCostPerNight
  );
};

const callFetchPartnerAndCountryDriverCost = (
  busPartnerId,
  countryId,
  productionCostYear,
  dispatch
) => {
  dispatch(
    fetchPartnerAndCountryDriverCost(
      busPartnerId,
      countryId,
      productionCostYear
    )
  );
};
