import React, {useEffect, useState} from 'react';
import {Input, Tooltip} from '@flixbus/honeycomb-react';
import {IconAttention, Icon} from '@flixbus/honeycomb-icons-react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {updateLineVehicle} from '../../../store/slice';
import {isNil} from 'ramda';
import {getInlineIconProps} from './Utils';
import {vehicleValidationSchema} from '../../../utils/validateVehicles';
import {validateInputNumber} from '../../../../Common/Utils';

const TooltipInput = ({
  id,
  valueKey,
  tooltipContent,
  value,
  lineVehicleId,
  vehicleId,
  name,
  label,
  disabled,
  inlineLabel,
  validationMethod
}) => {
  TooltipInput.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.number,
    valueKey: PropTypes.string.isRequired,
    tooltipContent: PropTypes.any.isRequired,
    vehicleId: PropTypes.string.isRequired,
    lineVehicleId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    inlineLabel: PropTypes.string,
    validationMethod: PropTypes.func
  };

  const dispatch = useDispatch();
  const driverCostCalculationType = useSelector(
    state => state.productionCost.driverCostCalculationType
  );

  const [activeTooltip, setActiveTooltip] = useState(false);
  const [isValid, setValid] = useState(true);
  const [infoError, setInfoError] = useState('');

  useEffect(() => {
    const validation = validationMethod
      ? validationMethod
      : vehicleValidationSchema(driverCostCalculationType)[valueKey];

    if (validation) {
      const validationResult = validation(value);
      setValid(validationResult[0]);
      setInfoError(validationResult[1]);
    }
  }, [value, validationMethod, driverCostCalculationType, valueKey]);

  const handleUpdateVehicle = (key, val) => {
    dispatch(
      updateLineVehicle({
        id: lineVehicleId,
        vehicleId: vehicleId,
        [key]: val || null
      })
    );
  };

  const handleOnInputChange = editedValue => {
    const integerDigitsCount = 6;
    const fractionDigitsCount = [
      'numberOfDrivers',
      'driverSalary',
      'flixStandardCostOverwrite',
      'totalDriverCostOverwrite'
    ].includes(valueKey)
      ? 0
      : 2;
    const allowNegative = valueKey === 'salaryChange';
    const newValue = validateInputNumber(
      editedValue,
      value,
      integerDigitsCount,
      fractionDigitsCount,
      allowNegative
    );

    handleUpdateVehicle(valueKey, getValue(newValue));
  };

  const getValue = value => {
    return isNil(value) ? '' : Number(value);
  };

  return (
    <Tooltip
      id={`tooltip-${id}`}
      content={tooltipContent}
      position="bottom"
      active={activeTooltip}
      onMouseOut={() => setActiveTooltip(false)}
    >
      <Input
        disabled={disabled}
        id={id}
        name={name}
        label={label}
        inlineLabelLeft={inlineLabel || ''}
        iconLeft={
          <Icon
            InlineIcon={IconAttention}
            {...getInlineIconProps(setActiveTooltip)}
          />
        }
        value={getValue(value)}
        onChange={e => handleOnInputChange(getValue(e.target.value))}
        type={'number'}
        onWheelCapture={e => e.target.blur()}
        valid={isValid}
        infoError={infoError}
      />
    </Tooltip>
  );
};

export default React.memo(TooltipInput);
