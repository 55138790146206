import DriverCostCalculationTypes from 'Pages/ProductionCost/constants/driverCostCalculationTypes';

export const DriverCostCalculationTypeOptions = [
  {
    id: 'type-driver_schedule_hours',
    value: DriverCostCalculationTypes.DRIVER_SCHEDULE_HOURS.value,
    name: 'driverCostCalculationType',
    label: DriverCostCalculationTypes.DRIVER_SCHEDULE_HOURS.label
  },
  {
    id: 'type-driver_cost_overwrite',
    value: DriverCostCalculationTypes.DRIVER_COST_OVERWRITE.value,
    name: 'driverCostCalculationType',
    label: DriverCostCalculationTypes.DRIVER_COST_OVERWRITE.label
  },
  {
    id: 'type-driver_salary',
    value: DriverCostCalculationTypes.DRIVER_SALARY.value,
    name: 'driverCostCalculationType',
    label: DriverCostCalculationTypes.DRIVER_SALARY.label
  }
];
