import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {Button} from '@flixbus/honeycomb-react';
import {
  Icon,
  IconArrowLeft,
  IconArrowRight
} from '@flixbus/honeycomb-icons-react';
import {
  backToFirstDataImportScreen,
  releasedProductionCost
} from '../../store/slice';
import {calculateProductionCost} from '../../store/thunks';
import {resetDataTableEntries} from '../../../Home/store/slice';
import {CalculationUserJourneyMode} from 'Pages/ProductionCost/store/types';
import {useProductionCostErrors} from './ProductionCostErrorNotification/useProductionCostErrors';

const SchedulesFooter = () => {
  const dispatch = useDispatch();
  const [isCalculateAndSaveBtnDisabled, setIsCalculateAndSaveBtnDisabled] =
    useState(false);

  const calculationUserJourneyMode = useSelector(
    state => state?.productionCost.calculationUserJourneyMode
  );

  const isVehiclesValid = useSelector(state =>
    state?.productionCost.busScheduleValues.every(
      busSchedule => busSchedule.isValid
    )
  );
  const productionCostCalculationErrors = useProductionCostErrors();
  useEffect(() => {
    if (productionCostCalculationErrors.isErrorFound) window.scrollTo(0, 0);
  }, [productionCostCalculationErrors]);

  const navigate = useNavigate();

  const onClickBack = () => {
    if (
      calculationUserJourneyMode === CalculationUserJourneyMode.EditCalculation
    ) {
      dispatch(releasedProductionCost());
      dispatch(resetDataTableEntries());
      navigate(-1); // go back to previous screen
    } else {
      dispatch(backToFirstDataImportScreen());
    }
  };

  const onClickNext = () => {
    setIsCalculateAndSaveBtnDisabled(true);
    dispatch(calculateProductionCost(setIsCalculateAndSaveBtnDisabled));
  };

  return (
    <div className={`line-schedules-footer second`}>
      <Button onClick={onClickBack}>
        <Icon InlineIcon={IconArrowLeft} aria-hidden="true" />
        Back
      </Button>
      <Button
        appearance="primary"
        onClick={onClickNext}
        disabled={!isVehiclesValid || isCalculateAndSaveBtnDisabled}
      >
        {calculationUserJourneyMode ===
        CalculationUserJourneyMode.EditCalculation
          ? 'Calculate & Save'
          : 'Next'}
        <Icon InlineIcon={IconArrowRight} aria-hidden="true" />
      </Button>
    </div>
  );
};

export default React.memo(SchedulesFooter);
