import React, {useCallback} from 'react';
import {isNil} from 'ramda';
import {useDispatch, useSelector} from 'react-redux';
import {updateLineVehicle} from '../../../store/slice';
import PropTypes from 'prop-types';
import {VehicleTypeCostsSelect} from './VehicleTypeCostsSelect';

const VehicleType = ({
  id,
  busTypeCostEntryId,
  vehicleId,
  lineVehicleId,
  busPartner
}) => {
  VehicleType.propTypes = {
    id: PropTypes.string.isRequired,
    busPartnerBusTypeCostEntryId: PropTypes.string,
    busPartner: PropTypes.object,
    vehicleId: PropTypes.string.isRequired,
    lineVehicleId: PropTypes.string.isRequired
  };
  const dispatch = useDispatch();

  const productionCostYear = useSelector(
    state => state?.productionCost.costYear
  );

  const handleUpdateVehicle = useCallback(
    (key, val) => {
      dispatch(
        updateLineVehicle({
          id: lineVehicleId,
          vehicleId: vehicleId,
          [key]: val || null
        })
      );
    },
    [lineVehicleId, vehicleId, dispatch]
  );

  const handleSelectChange = useCallback(
    (value, groupLabel) => {
      const updateCountry = groupLabel.includes('Country');
      handleUpdateVehicle(
        'busPartnerBusTypeCostEntryId',
        updateCountry ? null : value
      );
      handleUpdateVehicle(
        'countryBusTypeCostEntryId',
        updateCountry ? value : null
      );
    },
    [handleUpdateVehicle]
  );

  return (
    <VehicleTypeCostsSelect
      id={id}
      busPartner={busPartner}
      year={productionCostYear}
      selectedBusTypeCostEntryId={busTypeCostEntryId}
      valid={!isNil(busTypeCostEntryId)}
      disabled={isNil(busPartner?.id)}
      onChange={handleSelectChange}
    />
  );
};

export default React.memo(VehicleType);
