import {gql} from '@apollo/client';

const PRODUCTION_COST_SCHEDULES_WITH_PROVIDED_VALUES = gql`
  query productionCostSchedulesWithProvidedValues($productionCostEntryId: ID!) {
    productionCostSchedulesWithProvidedValues(
      productionCostEntryId: $productionCostEntryId
    ) {
      scheduleId
      startDate
      endDate
      lineNumber
      name
      description
      size
      busScheduleProvidedValuesEntries {
        id
        vehicleId
        busPartnerId
        busPartnerBusTypeCostEntryId
        countryBusTypeCostEntryId
        countryId
        timeZone
        emptyKmPerDay
        emptyHrPerDay
        countryDriverConceptTypeId
        salaryChange
        accommodationsPerWeek
        accommodationCostPerNight
        replacementKmPerDay
        replacementHrPerDay
        additionalCostPerTrip
        totalDriverCostOverwrite
        numberOfDrivers
        driverSalary
        busUtilizationFactor
        flixStandardCostOverwrite
        currency
      }
    }
  }
`;

export default PRODUCTION_COST_SCHEDULES_WITH_PROVIDED_VALUES;
