import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {
  Autocomplete,
  AutocompleteInput,
  AutocompleteOptions,
  Tooltip
} from '@flixbus/honeycomb-react';
import {Icon, IconAttention} from '@flixbus/honeycomb-icons-react';
import {isNil} from 'ramda';
import {useDispatch, useSelector} from 'react-redux';
import fieldInfoContent from '../../../constants/fieldInfoContent';
import {isBlank, isNotNil} from 'ramda-adjunct';
import {updateLineVehicle} from '../../../store/slice';
import {fetchCountryPrefilledEmptyValues} from '../../../store/thunks';
import {
  getAccommodationCostPerNight,
  getInlineIconProps,
  getTimeZones
} from './Utils';
import {CalculationUserJourneyMode} from 'Pages/ProductionCost/store/types';

const PartnerNameAutocompleteField = ({
  id,
  lineVehicleId,
  vehicleId,
  busPartnerId,
  disabled
}) => {
  const dispatch = useDispatch();

  const partners = useSelector(state =>
    !isNil(state.partners?.list) ? state.partners.list : []
  );
  const countries = useSelector(state => state.countries?.list);
  const busPartnerAndCountryDriverCostEntry = useSelector(
    state => state.productionCost.busPartnerAndCountryDriverCostEntry
  );
  const countryPrefilledEmptyValues = useSelector(
    state => state.productionCost.countryPrefilledEmptyValues
  );
  const productionCostYear = useSelector(
    state => state.productionCost.costYear
  );
  const countryDriverConceptTypes = useSelector(
    state => state.productionCost.countryDriverConceptTypes
  );

  const calculationUserJourneyMode = useSelector(
    state => state.productionCost.calculationUserJourneyMode
  );

  const [partnerTitle, setPartnerTitle] = useState('');
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [activeTooltip, setActiveTooltip] = useState(false);

  const partnerOptions = useMemo(
    () =>
      partners.map(partner => ({
        title: partner.name,
        value: partner.id,
        countryId: partner.countryId
      })),
    [partners]
  );

  const getPartner = useCallback(() => {
    let partner = null;
    if (isNotNil(busPartnerId)) {
      partner = partnerOptions?.find(partner => partner.value === busPartnerId);
    }
    return partner;
  }, [busPartnerId, partnerOptions]);

  useEffect(() => {
    const partner = getPartner();
    const partnerTitle = isBlank(partner?.title) ? '' : partner.title;
    setPartnerTitle(partnerTitle);
  }, [getPartner]);

  useEffect(() => {
    const partner = getPartner();
    if (
      isNotNil(partner) &&
      calculationUserJourneyMode ===
        CalculationUserJourneyMode.CreateCalculation
    ) {
      handleSelectOnPartnerName(partner);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getDriverConcepts = countryId => {
    return !isNil(countryDriverConceptTypes[countryId])
      ? countryDriverConceptTypes[countryId]
      : [];
  };

  const handleSelectOnPartnerName = val => {
    const timeZones = getTimeZones(countries, val.countryId);
    const timeZone = isNil(timeZones)
      ? null
      : timeZones.length === 1
      ? timeZones[0]
      : null;

    const countryPrefilledEmptyValuesEntry =
      countryPrefilledEmptyValues[val.countryId];
    if (isNil(countryPrefilledEmptyValuesEntry)) {
      dispatch(fetchCountryPrefilledEmptyValues(val.countryId));
    }

    const driverConcepts = getDriverConcepts(val.countryId);
    const countryDriverConceptTypeId = isNil(driverConcepts)
      ? null
      : driverConcepts.length === 1
      ? driverConcepts[0].id
      : null;

    const accommodationCostPerNight = getAccommodationCostPerNight(
      val.value,
      val.countryId,
      productionCostYear,
      busPartnerAndCountryDriverCostEntry,
      dispatch
    );

    dispatch(
      updateLineVehicle({
        id: lineVehicleId,
        vehicleId: vehicleId,
        busPartnerId: val.value,
        countryId: val.countryId,
        timeZone: timeZone,
        busPartnerBusTypeCostEntryId: null,
        countryBusTypeCostEntryId: null,
        accommodationCostPerNight,
        countryDriverConceptTypeId: countryDriverConceptTypeId,
        emptyKmPerDay: isNil(countryPrefilledEmptyValuesEntry)
          ? null
          : countryPrefilledEmptyValuesEntry.prefilledEmptyKm,
        emptyHrPerDay: isNil(countryPrefilledEmptyValuesEntry)
          ? null
          : countryPrefilledEmptyValuesEntry.prefilledEmptyHr
      })
    );
  };

  const filterAutocompleteData = (searchQuery, data) =>
    new Promise(resolve => {
      setTimeout(() => {
        const res = data.filter(item =>
          item.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
        resolve(res);
      }, 200);
    });

  const TooltipInlineIcon = () => {
    return <IconAttention {...getInlineIconProps(setActiveTooltip)} />;
  };

  return (
    <Tooltip
      id={`tooltip-${id}`}
      content={fieldInfoContent.partnerNameForCalculation}
      position="bottom"
      active={activeTooltip}
      onMouseOut={() => setActiveTooltip(false)}
    >
      <Autocomplete
        options={data ?? []}
        value={partnerTitle || ''}
        extraClasses={'partner-name-autocomplete'}
        onDebounce={e => {
          setLoading(true);

          filterAutocompleteData(e.target.value, partnerOptions).then(
            options => {
              setData(options);
              setLoading(false);
            }
          );
        }}
        onChange={e => {
          setLoading(true);
          setPartnerTitle(e.target.value);
        }}
        onSelect={item => {
          setData(null);
          setPartnerTitle(item.title);
          handleSelectOnPartnerName(item);
        }}
      >
        <AutocompleteInput
          id={id}
          label="Partner name for calculation*"
          placeholder=""
          disabled={disabled}
          valid={!isNil(busPartnerId)}
          loading={loading}
          iconLeft={<Icon InlineIcon={TooltipInlineIcon} />}
        />
        <AutocompleteOptions
          label="Partner name for calculation*"
          optionsToDisplay={4}
        />
      </Autocomplete>
    </Tooltip>
  );
};

export default React.memo(PartnerNameAutocompleteField);
