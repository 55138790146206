import {useEffect, useState} from 'react';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {Partner} from 'Pages/Partners/store/types';

export const useBusPartner = (busPartnerId: string | null): Partner | null => {
  const [busPartner, setBusPartner] = useState<Partner | null>(null);
  const partner = useAppSelector(({partners}) =>
    partners.list?.find(partner => partner.id === busPartnerId)
  );

  useEffect(() => {
    setBusPartner(partner || null);
  }, [partner]);

  return busPartner;
};
