import {
  CalculationUserJourneyMode,
  DriverCostCalculationType,
  LeasingCostCalculationType,
  PlanrLineSchedule,
  ProductionCostFilterType,
  ProductionCostState
} from './types';

export const getInitLineSchedule: () => PlanrLineSchedule = () => ({
  // used only on the FE side for updating store, as a key, etc
  lineScheduleKey: Math.random(),
  isValid: false,
  toggle: true,
  lineNumber: null,
  lineUuid: null,
  scheduleStatus: null,
  scheduleType: null,
  schedules: [],
  productionCostBuses: null
});

export const productionCostsInitialState: ProductionCostState = {
  id: null,
  name: null,
  startDate: null,
  endDate: null,
  status: null,
  costYear: null,
  lines: [],
  lineSchedules: [getInitLineSchedule()],
  productionCostBuses: null,
  countryPrefilledEmptyValues: {},
  countryDriverConceptTypes: {},
  busPartnerAndCountryDriverCostEntry: {},
  busScheduleValues: null,
  calculatedProductionCosts: null,
  errors: {},
  loading: false,
  driverCostCalculationType: DriverCostCalculationType.DriverScheduleHours,
  leasingCostCalculationType: LeasingCostCalculationType.Standard,
  calculationUserJourneyMode: CalculationUserJourneyMode.CreateCalculation,
  planrBusPartners: [],
  filteredPlanrBusPartnerIds: [],
  planrVehicles: [],
  filteredPlanrVehicleIds: [],
  filterType: ProductionCostFilterType.AllVehicles,
  released: null
};
