import {isNil} from 'ramda';
import DriverCostCalculationTypes from '../constants/driverCostCalculationTypes';
import {isBlank} from 'ramda-adjunct';
import {
  DriverCostCalculationType,
  ProductionCostBusScheduleProvidedValueEntry
} from '../store/types';

const isNotNilAndZero = val => !isNil(val) && val !== 0;

interface VehicleValidationSchema {
  id: (val) => [boolean, string];
  busPartnerId: (val) => [boolean, string];
  countryId: (val) => [boolean, string];
  busPartnerBusTypeCostEntryId: (val) => [boolean, string];
  countryDriverConceptTypeId: (val) => [boolean, string];
  totalDriverCostOverwrite: (val) => [boolean, string];
  driverSalary: (val) => [boolean, string];
  numberOfDrivers: (val) => [boolean, string];
  busUtilizationFactor: (val) => [boolean, string];
  flixStandardCostOverwrite: (val) => [boolean, string];
}

export const vehicleValidationSchema: (
  driverCostCalculationType: DriverCostCalculationType | null,
  vehicle?: ProductionCostBusScheduleProvidedValueEntry
) => VehicleValidationSchema = (driverCostCalculationType, vehicle) => {
  return {
    id: val => [!isNil(val), ''],
    busPartnerId: val => [!isNil(val), ''],
    countryId: val => [!isNil(val), ''],
    busPartnerBusTypeCostEntryId: val => [
      !isNil(val) || !isNil(vehicle?.countryBusTypeCostEntryId),
      ''
    ],
    countryDriverConceptTypeId: val => [
      driverCostCalculationType !==
        DriverCostCalculationTypes.DRIVER_SCHEDULE_HOURS.value || !isNil(val),
      ''
    ],
    totalDriverCostOverwrite: val => [
      driverCostCalculationType !==
        DriverCostCalculationTypes.DRIVER_COST_OVERWRITE.value ||
        isNotNilAndZero(val),
      ''
    ],
    driverSalary: val => [
      driverCostCalculationType !==
        DriverCostCalculationTypes.DRIVER_SALARY.value || isNotNilAndZero(val),
      ''
    ],
    numberOfDrivers: val => [
      driverCostCalculationType !==
        DriverCostCalculationTypes.DRIVER_SALARY.value || isNotNilAndZero(val),
      ''
    ],
    busUtilizationFactor: val => [
      isBlank(val) || (!isNil(val) && val > 0 && val < 100),
      ''
    ],
    flixStandardCostOverwrite: val => {
      const isValid = isBlank(val) || !isBlank(vehicle?.busUtilizationFactor);
      return [
        isValid,
        isValid ? '' : 'Value requires vehicle utilization factor'
      ];
    }
  };
};

const validate = (
  vehicle: ProductionCostBusScheduleProvidedValueEntry,
  schema: VehicleValidationSchema
) =>
  Object.entries(schema)
    .map(([property, validateMethod]) => [
      property,
      validateMethod(vehicle[property])
    ])
    .reduce((errors, [property, validateResult]) => {
      if (!validateResult[0]) {
        if (!isBlank(validateResult[1])) {
          errors.push(validateResult[1]);
        } else {
          errors.push(new Error(`${property} is invalid.`));
        }
      }
      return errors;
    }, []);

export const isVehicleValid = (
  vehicle: ProductionCostBusScheduleProvidedValueEntry,
  driverCostCalculationType: DriverCostCalculationType
) => {
  const result = validate(
    vehicle,
    vehicleValidationSchema(driverCostCalculationType, vehicle)
  );
  return result.length === 0;
};
