import React from 'react';
import {Fieldset} from '@flixbus/honeycomb-react';
import DriverCostCalculationType from './DriverCostCalculationType';
import LeasingCostCalculationTypeSelector from './LeasingCostCalculationTypeSelector';

const LinesHeader = () => {
  return (
    <div>
      <Fieldset
        key="key-fieldset-driver-cost-calculation-type"
        horizontal
        itemSpacing="12"
      >
        <DriverCostCalculationType />
        <LeasingCostCalculationTypeSelector />
      </Fieldset>
    </div>
  );
};

export default React.memo(LinesHeader);
