import React, {ChangeEvent, useState} from 'react';
import {Select, SelectOption, Tooltip} from '@flixbus/honeycomb-react';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {productionCostBaseUpdate} from 'Pages/ProductionCost/store/slice';
import {LeasingCostCalculationType} from 'Pages/ProductionCost/store/types';
import {getSelectInfoIcon} from '../LineVehicle/Utils';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';

const LeasingCostCalculationTypeSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  const [activeTooltip, setActiveTooltip] = useState(false);
  const id = 'leasing-cost-calculation-type';

  const leasingCostCalculationType = useAppSelector(
    ({productionCost}) => productionCost.leasingCostCalculationType
  );

  const onClickOption = (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(
      productionCostBaseUpdate({
        leasingCostCalculationType: event.target
          .value as LeasingCostCalculationType
      })
    );
  };

  return (
    <div className="select-with-tooltip">
      <Tooltip
        id={`${id}-tooltip`}
        content="
          In the CPC standard approach, the leasing/amortisation costs per vehicle & schedule are based on the duration of the schedule.
          The alternative modes translate a part of the monthly fixed cost into variable cost and are thus beneficial for Flix.
          Further information is provided in the CPC user documentation.
        "
        position="bottom"
        active={activeTooltip}
        onMouseOut={() => setActiveTooltip(false)}
      >
        <Select
          id={`${id}-selector`}
          label="Leasing/amortisation cost calculation"
          value={leasingCostCalculationType}
          onChange={onClickOption}
          extraClasses="hcr-space-1-right"
        >
          <SelectOption value={LeasingCostCalculationType.Standard}>
            CPC standard (consider schedule days)
          </SelectOption>
          <SelectOption value={LeasingCostCalculationType.FixedSplit30To70}>
            Split fixed cost: 30% operating days, 70% mileage
          </SelectOption>
          <SelectOption value={LeasingCostCalculationType.FixedSplit25To75}>
            Split fixed cost: 25% operating days, 75% mileage
          </SelectOption>
        </Select>
      </Tooltip>
      {getSelectInfoIcon(id, setActiveTooltip)}
    </div>
  );
};

export default React.memo(LeasingCostCalculationTypeSelector);
