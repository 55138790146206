import {gql} from '@apollo/client';

const IMPORT_PRODUCTION_COST_DATA = gql`
  mutation importProductionCostData($id: ID!, $scheduleIds: [ID!]!) {
    importProductionCostData(
      productionCostEntryId: $id
      scheduleIds: $scheduleIds
    ) {
      scheduleId
      startDate
      endDate
      lineNumber
      name
      description
      size
      busScheduleProvidedValuesEntries {
        id
        vehicleId
        busPartnerId
        busPartnerBusTypeCostEntryId
        countryBusTypeCostEntryId
        countryId
        timeZone
        emptyKmPerDay
        emptyHrPerDay
        countryDriverConceptTypeId
        salaryChange
        accommodationsPerWeek
        accommodationCostPerNight
        replacementKmPerDay
        replacementHrPerDay
        additionalCostPerTrip
        totalDriverCostOverwrite
        driverSalary
        numberOfDrivers
        flixStandardCostOverwrite
        busUtilizationFactor
        currency
      }
    }
  }
`;

export default IMPORT_PRODUCTION_COST_DATA;
