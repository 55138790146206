import React from 'react';
import {SelectGroup} from '@flixbus/honeycomb-react';
import {DriverCostCalculationTypeOptions} from './DriverCostCalculationType.constants';
import {useDispatch, useSelector} from 'react-redux';
import {
  productionCostBaseUpdate,
  validateBusScheduleValues
} from '../../../store/slice';

const DriverCostCalculationType = () => {
  const dispatch = useDispatch();
  const driverCostCalculationType = useSelector(
    state => state.productionCost.driverCostCalculationType
  );

  const onClickOption = event => {
    const driverCostCalculationTypeSelected = event.target.value;
    dispatch(
      productionCostBaseUpdate({
        driverCostCalculationType: driverCostCalculationTypeSelected
      })
    );

    dispatch(validateBusScheduleValues()); // validate all vehicles
  };

  const getOptionsWithDefaultSelection = () => {
    return DriverCostCalculationTypeOptions.map(option => {
      option = {...option, onClick: onClickOption};
      if (option.value === driverCostCalculationType)
        return {...option, defaultChecked: true};
      else return option;
    });
  };

  return (
    <>
      <SelectGroup
        label="Driver cost calculation logic *"
        options={getOptionsWithDefaultSelection()}
        id="id-driver-cost-calculation-type"
      />
    </>
  );
};

export default React.memo(DriverCostCalculationType);
