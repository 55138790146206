import React from 'react';
import {useSelector} from 'react-redux';
import {Box, Fieldset, Grid, GridCol, Input} from '@flixbus/honeycomb-react';
import PartnerNameAutocompleteField from './PartnerNameAutocompleteField';
import fieldInfoContent from '../../../constants/fieldInfoContent';
import CountryRules from './CountryRules';
import TimeZone from './TimeZone';
import DriverConcept from './DriverConcept';
import VehicleType from './VehicleType';
import PriceAccommodation from './PriceAccommodation';
import TooltipInput from './TooltipInput';
import PlanrPartnerName from './PlanrPartnerName';
import DriverCostCalculationTypes from '../../../constants/driverCostCalculationTypes';
import BusUtilization from './BusUtilization';
import {useProductionCostErrors} from '../ProductionCostErrorNotification/useProductionCostErrors';
import ProductionCostErrorNotification from '../ProductionCostErrorNotification';
import CopyVehicle from './CopyVehicle';
import {useBusPartner} from '../../../../PartnerCosts/hooks/useBusPartner';
import {CpcIconAttention} from '../../../../Common/CpcIcons';
import {CalculationUserJourneyMode} from 'Pages/ProductionCost/store/types';
import {useLocalUnitsContext} from '../../../../Common/LocalUnits';
import {getKmsOrMiles} from '../../../../Common/LabelUtils';

const LineVehicle = ({vehicle, index, scheduleSize}) => {
  const {localUnits} = useLocalUnitsContext();
  const productionCostBuses = useSelector(
    state => state?.productionCost?.productionCostBuses
  );
  const driverCostCalculationType = useSelector(
    state => state?.productionCost?.driverCostCalculationType
  );
  const productionCostCalculationErrors = useProductionCostErrors(
    vehicle.id,
    vehicle.vehicleId
  );
  const busPartner = useBusPartner(vehicle.busPartnerId);
  const productionCostBus = productionCostBuses.find(
    bus => bus.vehicleId === vehicle.vehicleId
  );

  const calculationUserJourneyMode = useSelector(
    state => state?.productionCost.calculationUserJourneyMode
  );
  const planrDataVersion = useSelector(
    ({productionCost}) => productionCost.planrDataVersion
  );

  const productionCostBusName = productionCostBus ? productionCostBus.name : '';

  const ridesBasedPlanrDataVersion = planrDataVersion === '1';

  const ids = {
    accommodationsWeek: `${index}-accomodations-week`,
    priceAccomodation: `${index}-price-accomodation`,
    vehicleEmptyKmId: `${index}-vehicle-empty-km`,
    driverChangeKmsOperatingDay: `${index}-driver-change-operating-day-km`,
    partnerNameForCalculation: `partner-autocomplete-${index}`,
    vehicleEmptyHours: `${index}-vehicle-empty-hours`,
    driverChangeHrsOperatingDay: `${index}-driver-change-operating-day`,
    addInfrastructureCost: `${index}-additional-infrastructure-cost`,
    salaryIncreaseDecrease: `${index}-salary-incrtease-decrease`,
    overwriteTotalDriverCost: `${index}-overwrite-total-driver-cost`,
    countryRules: `${index}-country-rules`,
    timeZone: `${index}-timezone`,
    numberOfDrivers: `${index}-numberOfDrivers`,
    driverSalary: `${index}-driverSalary`,
    busUtilizationFactor: `${index}-busUtilizationFactor`,
    flixStandardCostOverwrite: `${index}-flixStandardCostOverwrite`,
    copyVehicle: `${index}-copy-vehicle`
  };

  return (
    <div className={`vehicles-section-vehicle`}>
      <Box
        style={{
          borderColor: productionCostCalculationErrors.isVehicleErrorFound
            ? 'red'
            : undefined
        }}
      >
        {calculationUserJourneyMode ===
          CalculationUserJourneyMode.EditCalculation &&
          vehicle?.currency &&
          vehicle?.currency !== busPartner?.currency && (
            <div style={{color: '#FFAD00'}}>
              <CpcIconAttention /> Provided values for this vehicle were
              originally made in currency {vehicle?.currency}, please check the
              values carefully.
            </div>
          )}
        <ProductionCostErrorNotification
          productionCostCalculationErrors={
            productionCostCalculationErrors.errors
          }
        />
        <Grid>
          <GridCol key={`${index}-grid-col-1`}>
            <Grid>
              <GridCol key={`${index}-grid-col-1-1`}>
                <Fieldset>
                  <Input
                    id={`${index}-production-cost-bus-name`}
                    name="production-cost-bus-name"
                    label="Vehicle ID"
                    readOnly
                    value={productionCostBusName || ''}
                    extraClasses={`info-only`}
                  />
                </Fieldset>
              </GridCol>
            </Grid>

            <Grid>
              <GridCol key={`${index}-grid-col-1-2-1`}>
                <Fieldset>
                  <VehicleType
                    id={`${index}-vehicle-type`}
                    busTypeCostEntryId={
                      vehicle.busPartnerBusTypeCostEntryId ??
                      vehicle.countryBusTypeCostEntryId
                    }
                    busPartner={busPartner}
                    lineVehicleId={vehicle.id}
                    vehicleId={vehicle.vehicleId}
                  />
                </Fieldset>
              </GridCol>
            </Grid>

            <Grid>
              <>
                {driverCostCalculationType ===
                  DriverCostCalculationTypes.DRIVER_SCHEDULE_HOURS.value && (
                  <GridCol key={`${index}-grid-col-1-3-1`}>
                    <Fieldset>
                      <DriverConcept
                        id={`${index}-driver-concept`}
                        key={`${index}-driver-concept`}
                        countryDriverConceptTypeId={
                          vehicle.countryDriverConceptTypeId
                        }
                        countryId={vehicle.countryId}
                        lineVehicleId={vehicle.id}
                        vehicleId={vehicle.vehicleId}
                      />
                    </Fieldset>
                  </GridCol>
                )}

                {driverCostCalculationType ===
                  DriverCostCalculationTypes.DRIVER_COST_OVERWRITE.value && (
                  <GridCol key={`${index}-grid-col-1-3-2`}>
                    <Fieldset>
                      <TooltipInput
                        id={ids.overwriteTotalDriverCost}
                        key={`key-${ids.overwriteTotalDriverCost}`}
                        valueKey={'totalDriverCostOverwrite'}
                        tooltipContent={
                          fieldInfoContent.overwriteTotalDriverCost
                        }
                        value={vehicle.totalDriverCostOverwrite}
                        lineVehicleId={vehicle.id}
                        vehicleId={vehicle.vehicleId}
                        name="overwrite-total-driver-cost"
                        label="Overwrite total driver cost*"
                        inlineLabel={busPartner?.currency}
                      />
                    </Fieldset>
                  </GridCol>
                )}

                {driverCostCalculationType ===
                  DriverCostCalculationTypes.DRIVER_SALARY.value && (
                  <>
                    <GridCol key={`${index}-grid-col-1-3-3`}>
                      <Fieldset>
                        <TooltipInput
                          id={ids.numberOfDrivers}
                          key={`key-${ids.numberOfDrivers}`}
                          valueKey={'numberOfDrivers'}
                          tooltipContent={fieldInfoContent.numberOfDrivers}
                          value={vehicle.numberOfDrivers}
                          lineVehicleId={vehicle.id}
                          vehicleId={vehicle.vehicleId}
                          name="number-of-drivers"
                          label="Number of drivers*"
                        />
                      </Fieldset>
                    </GridCol>
                    <GridCol key={`${index}-grid-col-1-3-4`}>
                      <Fieldset>
                        <TooltipInput
                          id={ids.driverSalary}
                          key={`key-${ids.driverSalary}`}
                          valueKey={'driverSalary'}
                          tooltipContent={fieldInfoContent.driverSalary}
                          value={vehicle.driverSalary}
                          lineVehicleId={vehicle.id}
                          vehicleId={vehicle.vehicleId}
                          name="driver-Salary"
                          label="Annual driver salary*"
                          inlineLabel={busPartner?.currency}
                        />
                      </Fieldset>
                    </GridCol>
                  </>
                )}
              </>
            </Grid>
          </GridCol>

          <GridCol key={`${index}-grid-col-2`}>
            <Grid>
              <GridCol key={`${index}-grid-col-2-1-1`}>
                <Fieldset>
                  <PlanrPartnerName
                    index={index}
                    productionCostBus={productionCostBus}
                  />
                </Fieldset>
              </GridCol>
            </Grid>
            <Grid extraClasses={'no-wrap'}>
              <GridCol key={`${index}-grid-col-2-2-1`}>
                <Fieldset>
                  <TooltipInput
                    id={ids.vehicleEmptyKmId}
                    key={`key-${ids.vehicleEmptyKmId}`}
                    valueKey={'emptyKmPerDay'}
                    tooltipContent={fieldInfoContent.emptyKmsOperatingDay(
                      localUnits
                    )}
                    value={vehicle.emptyKmPerDay}
                    lineVehicleId={vehicle.id}
                    vehicleId={vehicle.vehicleId}
                    name="vehicle-empty-km"
                    label={`Empty ${getKmsOrMiles(localUnits)}/operating day`}
                  />
                </Fieldset>
              </GridCol>
              <>
                {driverCostCalculationType ===
                  DriverCostCalculationTypes.DRIVER_SCHEDULE_HOURS.value && (
                  <GridCol key={`${index}-grid-col-2-2-2`}>
                    <Fieldset>
                      <TooltipInput
                        id={ids.vehicleEmptyHours}
                        key={`key-${ids.vehicleEmptyHours}`}
                        valueKey={'emptyHrPerDay'}
                        tooltipContent={fieldInfoContent.emptyHoursOperatingDay}
                        value={vehicle.emptyHrPerDay}
                        lineVehicleId={vehicle.id}
                        vehicleId={vehicle.vehicleId}
                        name="vehicle-empty-hours"
                        label="Empty hours/operating day"
                        inlineLabel="Hrs"
                      />
                    </Fieldset>
                  </GridCol>
                )}
              </>
            </Grid>
            <Grid>
              <>
                {driverCostCalculationType ===
                  DriverCostCalculationTypes.DRIVER_SCHEDULE_HOURS.value && (
                  <GridCol key={`${index}-grid-col-2-3-1`}>
                    <Fieldset>
                      <TooltipInput
                        id={ids.salaryIncreaseDecrease}
                        key={`key-${ids.salaryIncreaseDecrease}`}
                        valueKey={'salaryChange'}
                        tooltipContent={fieldInfoContent.salaryIncreaseDecrease}
                        value={vehicle.salaryChange}
                        lineVehicleId={vehicle.id}
                        vehicleId={vehicle.vehicleId}
                        name="salary-incrtease-decrease"
                        label="Salary increase/decrease (all)"
                        inlineLabel={`${busPartner?.currency}/h`}
                      />
                    </Fieldset>
                  </GridCol>
                )}
              </>
            </Grid>
          </GridCol>

          <GridCol key={`${index}-grid-col-3`}>
            <Grid>
              <GridCol key={`${index}-grid-col-3-1`}>
                <Fieldset>
                  <PartnerNameAutocompleteField
                    key={`key-${ids.partnerNameForCalculation}`}
                    id={ids.partnerNameForCalculation}
                    lineVehicleId={vehicle.id}
                    vehicleId={vehicle.vehicleId}
                    busPartnerId={vehicle.busPartnerId}
                  />
                </Fieldset>
              </GridCol>
            </Grid>
            <Grid>
              <GridCol key={`${index}-grid-col-3-2-2`}>
                <Fieldset>
                  <TooltipInput
                    id={ids.addInfrastructureCost}
                    key={`key-${ids.addInfrastructureCost}`}
                    valueKey={'additionalCostPerTrip'}
                    tooltipContent={fieldInfoContent.addInfrastructureCost}
                    value={vehicle.additionalCostPerTrip}
                    lineVehicleId={vehicle.id}
                    vehicleId={vehicle.vehicleId}
                    name="additional-infrastructure-cost"
                    label="Add. infrastructure cost"
                    inlineLabel={`${busPartner?.currency}/trip`}
                  />
                </Fieldset>
              </GridCol>
            </Grid>

            <Grid>
              <>
                {driverCostCalculationType ===
                  DriverCostCalculationTypes.DRIVER_SCHEDULE_HOURS.value && (
                  <GridCol key={`${index}-grid-col-3-3-1`}>
                    <Fieldset>
                      <TooltipInput
                        id={ids.driverChangeHrsOperatingDay}
                        key={`key-${ids.driverChangeHrsOperatingDay}`}
                        valueKey={'replacementHrPerDay'}
                        tooltipContent={
                          fieldInfoContent.driverChangeHrsOperatingDay
                        }
                        value={vehicle.replacementHrPerDay}
                        lineVehicleId={vehicle.id}
                        vehicleId={vehicle.vehicleId}
                        name="driver-change-operating-day"
                        label="Driver change/operating day"
                        inlineLabel="Hrs"
                      />
                    </Fieldset>
                  </GridCol>
                )}
              </>
            </Grid>
          </GridCol>

          <GridCol key={`${index}-grid-col-4`}>
            <Grid>
              <GridCol key={`${index}-grid-col-4-1`}>
                <Fieldset>
                  <CountryRules
                    countryId={vehicle.countryId}
                    lineVehicleId={vehicle.id}
                    vehicleId={vehicle.vehicleId}
                    busPartnerCurrency={busPartner?.currency}
                    id={`id-${ids.countryRules}`}
                    key={`key-${ids.countryRules}`}
                  />
                </Fieldset>
              </GridCol>
              {ridesBasedPlanrDataVersion && (
                <GridCol key={`${index}-grid-col-4-2`}>
                  <Fieldset>
                    <TimeZone
                      id={`tooltip-${ids.timeZone}`}
                      key={`key-${ids.timeZone}`}
                      timeZone={vehicle.timeZone}
                      countryId={vehicle.countryId}
                      lineVehicleId={vehicle.id}
                      vehicleId={vehicle.vehicleId}
                    />
                  </Fieldset>
                </GridCol>
              )}
            </Grid>
            <Grid>
              <GridCol key={`${index}-grid-col-4-2-1`}>
                <Fieldset>
                  <TooltipInput
                    id={ids.accommodationsWeek}
                    key={`key-${ids.accommodationsWeek}`}
                    valueKey={'accommodationsPerWeek'}
                    tooltipContent={fieldInfoContent.accomodationsWeek}
                    value={vehicle.accommodationsPerWeek}
                    lineVehicleId={vehicle.id}
                    vehicleId={vehicle.vehicleId}
                    name="accomodations-week"
                    label="Accomodations/week"
                  />
                </Fieldset>
              </GridCol>
              <GridCol key={`${index}-grid-col-4-2-2`}>
                <Fieldset>
                  <PriceAccommodation
                    id={ids.priceAccomodation}
                    value={vehicle.accommodationCostPerNight}
                    lineVehicleId={vehicle.id}
                    vehicleId={vehicle.vehicleId}
                    busPartnerCurrency={busPartner?.currency}
                    busPartnerId={vehicle.busPartnerId}
                  />
                </Fieldset>
              </GridCol>
            </Grid>
            {driverCostCalculationType ===
              DriverCostCalculationTypes.DRIVER_SCHEDULE_HOURS.value && (
              <Grid>
                <GridCol key={`${index}-grid-col-3-3-2`}>
                  <Fieldset>
                    <TooltipInput
                      id={ids.driverChangeKmsOperatingDay}
                      key={`key-${ids.driverChangeKmsOperatingDay}`}
                      valueKey={'replacementKmPerDay'}
                      tooltipContent={fieldInfoContent.driverChangeKmsOperatingDay(
                        localUnits
                      )}
                      value={vehicle.replacementKmPerDay}
                      lineVehicleId={vehicle.id}
                      vehicleId={vehicle.vehicleId}
                      name="driver-change-km-operating-day"
                      label="Driver change/operating day"
                      inlineLabel={getKmsOrMiles(localUnits)}
                    />
                  </Fieldset>
                </GridCol>
              </Grid>
            )}
          </GridCol>
        </Grid>
        <BusUtilization
          lineVehicleId={vehicle.id}
          busUtilizationFactor={vehicle?.busUtilizationFactor}
          flixStandardCostOverwrite={vehicle?.flixStandardCostOverwrite}
          vehicleId={vehicle.vehicleId}
          busPartnerCurrency={busPartner?.currency}
          ids={ids}
          index={index}
          vehicle={vehicle}
        />
        <CopyVehicle
          key={ids.copyVehicle}
          lineVehicleId={vehicle.id}
          scheduleSize={scheduleSize}
        />
      </Box>
    </div>
  );
};

export default React.memo(LineVehicle);
