import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Select, SelectOption, Tooltip} from '@flixbus/honeycomb-react';
import {isNil} from 'ramda';
import {updateLineVehicle} from '../../../store/slice';
import PropTypes from 'prop-types';
import fieldInfoContent from '../../../constants/fieldInfoContent';
import {getSelectInfoIcon, getTimeZones} from './Utils';

const TimeZone = ({id, timeZone, countryId, lineVehicleId, vehicleId}) => {
  TimeZone.propTypes = {
    id: PropTypes.string.isRequired,
    timeZone: PropTypes.string,
    countryId: PropTypes.string,
    vehicleId: PropTypes.string.isRequired,
    lineVehicleId: PropTypes.string.isRequired
  };
  const dispatch = useDispatch();

  const countries = useSelector(state => state.countries?.list);

  const [activeTooltip, setActiveTooltip] = useState(false);

  const getTimezoneOptions = () => {
    const timeZones = getTimeZones(countries, countryId);

    return timeZones.map(timezone => (
      <SelectOption key={timezone} value={timezone}>
        {timezone}
      </SelectOption>
    ));
  };

  const handleSelectOnTimeZone = timeZone => {
    dispatch(
      updateLineVehicle({
        id: lineVehicleId,
        vehicleId: vehicleId,
        timeZone: timeZone
      })
    );
  };

  return (
    <div className="select-with-tooltip">
      <Tooltip
        id={id}
        content={fieldInfoContent.timeZone}
        position="bottom"
        active={activeTooltip}
        onMouseOut={() => setActiveTooltip(false)}
      >
        <Select
          valid={!isNil(timeZone)}
          disabled={isNil(countryId)}
          id={`select-${id}`}
          label="Time zone*"
          placeholder="Select a timezone"
          value={timeZone || ''}
          onChange={e => handleSelectOnTimeZone(e.target.value)}
        >
          {getTimezoneOptions()}
        </Select>
      </Tooltip>
      {getSelectInfoIcon(id, setActiveTooltip)}
    </div>
  );
};

export default React.memo(TimeZone);
