import React, {useEffect, useMemo} from 'react';
import {useQuery} from '@apollo/client';
import {
  Select,
  SelectOption,
  SelectOptGroup,
  Box,
  Skeleton
} from '@flixbus/honeycomb-react';
import {getCountryName} from 'Pages/Home/utils';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {
  VehicleTypeCost,
  VehicleTypeCostsSelectProps
} from './VehicleTypeCostsSelect.types';
import GET_VEHICLE_TYPE_COSTS_QUERY from './VehicleTypeCostsSelectQuery';
import {VehicleFuelType} from 'Pages/VehicleTypes/store/types';

const VehicleTypeCostsSelect: React.FC<VehicleTypeCostsSelectProps> = ({
  id,
  busPartner,
  year,
  selectedBusTypeCostEntryId,
  disabled = false,
  valid = true,
  onChange
}) => {
  const vehicleTypes =
    useAppSelector(({vehicleTypes}) => vehicleTypes.list) ?? [];

  const countries = useAppSelector(({countries}) => countries.list);

  const countryName: string = useMemo(
    () => getCountryName(countries, busPartner?.countryId),
    [countries, busPartner?.countryId]
  );

  const {loading, data} = useQuery(GET_VEHICLE_TYPE_COSTS_QUERY, {
    variables: {
      countryId: busPartner?.countryId,
      busPartnerId: busPartner?.id,
      year
    },
    errorPolicy: 'all'
  });

  const busPartnerBusTypeCostEntries: VehicleTypeCost[] = useMemo(
    () => data?.getBusPartnerCostEntryYear?.busTypeCostEntries ?? [],
    [data?.getBusPartnerCostEntryYear?.busTypeCostEntries]
  );

  const countryBusTypeCostEntries: VehicleTypeCost[] = useMemo(
    () => data?.countryCostEntry?.busTypeCostEntries ?? [],
    [data?.countryCostEntry?.busTypeCostEntries]
  );

  useEffect(() => {
    if (onChange && !loading) {
      if (
        busPartnerBusTypeCostEntries?.length === 1 &&
        countryBusTypeCostEntries?.length === 0 &&
        !selectedBusTypeCostEntryId
      ) {
        onChange(busPartnerBusTypeCostEntries[0].id, 'Bus Partner');
      } else if (
        busPartnerBusTypeCostEntries?.length === 0 &&
        countryBusTypeCostEntries?.length === 1 &&
        !selectedBusTypeCostEntryId
      ) {
        onChange(countryBusTypeCostEntries[0].id, 'Country');
      } else if (
        busPartnerBusTypeCostEntries?.length === 0 &&
        countryBusTypeCostEntries?.length === 0
      ) {
        onChange(null, 'Bus Partner');
        onChange(null, 'Country');
      }
    }
  }, [
    busPartnerBusTypeCostEntries,
    countryBusTypeCostEntries,
    selectedBusTypeCostEntryId,
    onChange,
    loading
  ]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    const groupLabel = (
      event.target.options[event.target.selectedIndex]
        ?.parentNode as HTMLOptGroupElement
    )?.label;

    if (onChange) {
      onChange(selectedValue, groupLabel);
    }
  };

  const getSelectOption = (busTypeCost: VehicleTypeCost) => {
    const vehicleType = vehicleTypes.find(
      (vehicleType: {id: string}) => vehicleType.id === busTypeCost.busTypeId
    );
    return (
      <SelectOption
        key={busTypeCost.id}
        value={busTypeCost.id}
        data-group="Bus Partner"
      >
        {`${vehicleType?.name}${
          busTypeCost.customIdentifier
            ? ` - ${busTypeCost.customIdentifier}`
            : ''
        }${
          busTypeCost.vehicleProductionYear
            ? ` - ${busTypeCost.vehicleProductionYear}`
            : ''
        }${
          vehicleType?.fuelType !== VehicleFuelType.DIESEL
            ? ` - ${vehicleType?.fuelType}`
            : ''
        }`}
      </SelectOption>
    );
  };

  return (
    <>
      {loading && (
        <Box
          aria-live="polite"
          aria-busy="true"
          small={true}
          data-testid="loading-skeleton"
        >
          <Skeleton width="md" height="sm" />
        </Box>
      )}

      {!loading && (
        <Select
          id={id}
          label="Vehicle type*"
          placeholder="Select a vehicle type"
          value={selectedBusTypeCostEntryId || ''}
          disabled={disabled}
          valid={valid}
          onChange={handleSelectChange}
        >
          <>
            {busPartnerBusTypeCostEntries?.length > 0 && (
              <SelectOptGroup label="Bus Partner">
                {busPartnerBusTypeCostEntries?.map(
                  (busTypeCost: VehicleTypeCost) => getSelectOption(busTypeCost)
                )}
              </SelectOptGroup>
            )}

            {countryBusTypeCostEntries?.length > 0 && (
              <SelectOptGroup label={`Country: ${countryName}`}>
                {countryBusTypeCostEntries?.map(
                  (busTypeCost: VehicleTypeCost) => getSelectOption(busTypeCost)
                )}
              </SelectOptGroup>
            )}
          </>
        </Select>
      )}
    </>
  );
};

export default React.memo(VehicleTypeCostsSelect);
