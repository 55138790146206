import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Select, SelectOption, Tooltip} from '@flixbus/honeycomb-react';
import {isNil} from 'ramda';
import {updateLineVehicle} from '../../../store/slice';
import PropTypes from 'prop-types';
import fieldInfoContent from '../../../constants/fieldInfoContent';
import {getSelectInfoIcon, getTimeZones} from './Utils';
import {getCountry} from '../../../../Home/utils';

const CountryRules = ({
  id,
  countryId,
  lineVehicleId,
  vehicleId,
  busPartnerCurrency
}) => {
  CountryRules.propTypes = {
    id: PropTypes.string.isRequired,
    countryId: PropTypes.string,
    lineVehicleId: PropTypes.string.isRequired,
    vehicleId: PropTypes.string.isRequired,
    busPartnerCurrency: PropTypes.string
  };
  const dispatch = useDispatch();
  const [activeTooltip, setActiveTooltip] = useState(false);

  const countryDriverConceptTypes = useSelector(
    ({productionCost}) => productionCost.countryDriverConceptTypes
  );
  const countries = useSelector(({countries}) => countries?.list);

  const getCountriesOptions = () => {
    if (isNil(countries)) {
      return [];
    }
    return countries
      .filter(
        country =>
          country?.currency === busPartnerCurrency || countryId === country?.id
      )
      .map(country => (
        <SelectOption key={country.id} value={country.id}>
          {country.name}
        </SelectOption>
      ));
  };

  const getDriverConcepts = countryId => {
    return !isNil(countryDriverConceptTypes[countryId])
      ? countryDriverConceptTypes[countryId]
      : [];
  };

  const handleSelectOnCountryRule = countryId => {
    const timeZones = getTimeZones(countries, countryId);
    const timeZone = timeZones.length === 1 ? timeZones[0] : null;

    const driverConcepts = getDriverConcepts(countryId);
    const countryDriverConceptTypeId = isNil(driverConcepts)
      ? null
      : driverConcepts.length === 1
      ? driverConcepts[0].id
      : null;

    dispatch(
      updateLineVehicle({
        id: lineVehicleId,
        vehicleId,
        timeZone,
        countryId,
        countryDriverConceptTypeId
      })
    );
  };

  return (
    <div className="select-with-tooltip">
      <Tooltip
        id={`tooltip-${id}`}
        content={fieldInfoContent.countryRules}
        position="bottom"
        active={activeTooltip}
        onMouseOut={() => setActiveTooltip(false)}
      >
        <Select
          valid={
            !isNil(countryId) &&
            getCountry(countries, countryId)?.currency === busPartnerCurrency
          }
          id={`select-${id}`}
          label="Country rules*"
          placeholder="Select a Country"
          value={countryId || ''}
          onChange={e => handleSelectOnCountryRule(e.target.value)}
        >
          {getCountriesOptions()}
        </Select>
      </Tooltip>
      {getSelectInfoIcon(id, setActiveTooltip)}
    </div>
  );
};

export default React.memo(CountryRules);
