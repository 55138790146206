import React from 'react';
import {Notification} from '@flixbus/honeycomb-react';
import {removeEditScreenError} from '../../../store/slice';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {ProductionCostError} from '../../types';

type Props = {
  productionCostCalculationErrors: ProductionCostError[];
};

const ProductionCostErrorNotification = ({
  productionCostCalculationErrors
}: Props) => {
  const dispatch = useAppDispatch();

  return (
    <>
      {productionCostCalculationErrors?.map(error => (
        <Notification
          key={`calculation-error-${error.id}`}
          appearance="danger"
          closeProps={{
            'aria-label': 'Close',
            onClick: () => dispatch(removeEditScreenError(error.id))
          }}
        >
          {error.message}
        </Notification>
      ))}
    </>
  );
};

export default React.memo(ProductionCostErrorNotification);
