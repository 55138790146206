import React from 'react';
import fieldInfoContent from '../../../constants/fieldInfoContent';
import {isNil} from 'ramda';
import PropTypes from 'prop-types';
import TooltipInput from './TooltipInput';

const PriceAccommodation = ({
  id,
  value,
  lineVehicleId,
  vehicleId,
  busPartnerId,
  busPartnerCurrency
}) => {
  PriceAccommodation.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.number,
    vehicleId: PropTypes.string.isRequired,
    lineVehicleId: PropTypes.string.isRequired,
    busPartnerId: PropTypes.string,
    busPartnerCurrency: PropTypes.string
  };

  return (
    <TooltipInput
      key={`key-${id}`}
      id={id}
      valueKey={'accommodationCostPerNight'}
      tooltipContent={fieldInfoContent.priceAccommodation}
      value={value}
      lineVehicleId={lineVehicleId}
      vehicleId={vehicleId}
      disabled={isNil(busPartnerId)}
      name="price-accomodation"
      label="Price/accomodation"
      inlineLabel={busPartnerCurrency}
    />
  );
};

export default React.memo(PriceAccommodation);
