export interface ProductionCostError {
  id: string;
  message: string;
  extensions: {
    errorType: string;
    providedValuesEntryId: string;
    errorArguments: string[];
  };
}

export class ProductionCostErrorResult {
  isErrorFound: boolean;
  isVehicleErrorFound: boolean;
  size: number;
  errors: ProductionCostError[];

  constructor(
    isErrorFound = false,
    isVehicleErrorFound = false,
    size = 0,
    errors: ProductionCostError[] = []
  ) {
    this.isErrorFound = isErrorFound;
    this.isVehicleErrorFound = isVehicleErrorFound;
    this.size = size;
    this.errors = errors;
  }
}
