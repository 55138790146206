import React, {useEffect} from 'react';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {getPartners} from 'Pages/Partners/store/thunks';
import {getCountries} from 'Pages/Countries/store/thunks';
import {getVehicleTypes} from 'Pages/VehicleTypes/store/thunks';
import {getProductionCostBuses} from 'Pages/ProductionCost/store/thunks';
import {ProductionCostHeaderStepOption} from '../ProductionCostHeader/ProductionCostHeaderSteps/ProductionCostHeaderSteps.types';
import VehicleServingSelectedLines from './VehicleServingSelectedLines';
import SchedulesFooter from './SchedulesFooter';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import ProductionCostHeader from '../ProductionCostHeader';
import styles from './index.module.scss';

const ProductionCostStepTwo: React.FC = () => {
  const dispatch = useAppDispatch();
  const partners = useAppSelector(({partners}) => partners.list);
  const countries = useAppSelector(({countries}) => countries.list);
  const vehicleTypes = useAppSelector(({vehicleTypes}) => vehicleTypes.list);
  const calculationUserJourneyMode = useAppSelector(
    ({productionCost}) => productionCost.calculationUserJourneyMode
  );

  useEffect(() => {
    if (!partners) dispatch(getPartners());
    if (!countries) dispatch(getCountries());
    if (!vehicleTypes) dispatch(getVehicleTypes());
    dispatch(getProductionCostBuses());
  }, [partners, countries, vehicleTypes, dispatch]);

  return (
    <>
      <div className={styles.header}>
        <ProductionCostHeader
          mode={calculationUserJourneyMode}
          step={ProductionCostHeaderStepOption.DataEntry}
        />
      </div>

      <VehicleServingSelectedLines />
      <SchedulesFooter />
    </>
  );
};

export default React.memo(ProductionCostStepTwo);
