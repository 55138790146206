import {isEmpty} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {isVehicleValid} from '../utils/validateVehicles';
import {CalculationUserJourneyMode} from 'Pages/ProductionCost/store/types';
import {convertDecimalToPercentage} from '../../Common/Utils';
import {copyVehicleToBusSchedules} from './utils';
import {productionCostsInitialState, getInitLineSchedule} from './data';
import {
  CalculatedProductionCost,
  CountryPrefilledEmptyValues,
  PlanrBusPartner,
  PlanrLine,
  PlanrSchedule,
  PlanrVehicle,
  ProductionCostBus,
  ProductionCostBusSchedule,
  ProductionCostBusScheduleProvidedValueEntry,
  ProductionCostFilterType,
  ProductionCostState
} from './types';
import {
  CountryDriverConceptType,
  RegionalCostsDriverCost
} from 'Pages/RegionalCosts/store/types';
import {PartnerCostsDriverCost} from 'Pages/PartnerCosts/store/types';

const productionCostsSlice = createSlice({
  name: 'production-costs',
  initialState: productionCostsInitialState,
  reducers: {
    errorsCalculateProductionCost: (
      state,
      {payload: productionCostCalculation}: PayloadAction<any>
    ) => {
      return {
        ...state,
        errors: {
          ...state.errors,
          productionCostCalculation
        }
      };
    },
    receivedCalculateProductionCost: (
      state,
      {
        payload: calculatedProductionCosts
      }: PayloadAction<CalculatedProductionCost>
    ) => {
      return {
        ...state,
        calculatedProductionCosts,
        errors: {}
      };
    },
    validateBusScheduleValues: state => {
      const driverCostCalculationType = state.driverCostCalculationType;
      const busScheduleValues = [...(state.busScheduleValues ?? [])].map(
        busSchedule => {
          let isValid = true;

          const busScheduleProvidedValuesEntries = [
            ...busSchedule.busScheduleProvidedValuesEntries
          ].map(bus => {
            if (!isVehicleValid(bus, driverCostCalculationType)) {
              isValid = false;
            }
            return bus;
          });

          return {
            ...busSchedule,
            isValid,
            busScheduleProvidedValuesEntries
          };
        }
      );

      return {
        ...state,
        busScheduleValues,
        errors: {}
      };
    },
    updateLineVehicle: (
      state,
      {payload}: PayloadAction<ProductionCostBusScheduleProvidedValueEntry>
    ) => {
      const driverCostCalculationType = state.driverCostCalculationType;
      const busScheduleValues = [...(state.busScheduleValues ?? [])].map(
        busSchedule => {
          let isValid = true;

          const busScheduleProvidedValuesEntries = [
            ...busSchedule.busScheduleProvidedValuesEntries
          ].map(bus => {
            const updatedBus = {...bus, ...payload};
            if (bus.id === payload.id) {
              if (!isVehicleValid(updatedBus, driverCostCalculationType)) {
                isValid = false;
              }
              return updatedBus;
            }

            if (!isVehicleValid(bus, driverCostCalculationType)) {
              isValid = false;
            }

            return bus;
          });

          return {
            ...busSchedule,
            isValid,
            busScheduleProvidedValuesEntries
          };
        }
      );

      return {
        ...state,
        busScheduleValues
      };
    },
    receivedProductionCostBuses: (
      state,
      {payload: productionCostBuses}: PayloadAction<ProductionCostBus[]>
    ) => {
      return {
        ...state,
        productionCostBuses
      };
    },
    receivedCountryPrefilledEmptyValuesForVehicle: (
      state,
      {
        payload: {countryId, countryPrefilledEmptyValues}
      }: PayloadAction<{
        countryId: string;
        countryPrefilledEmptyValues: CountryPrefilledEmptyValues;
      }>
    ) => {
      const busScheduleValues = [...(state.busScheduleValues ?? [])].map(
        busSchedule => {
          return {
            ...busSchedule,
            busScheduleProvidedValuesEntries:
              busSchedule.busScheduleProvidedValuesEntries.map(bus => {
                if (bus.countryId !== countryId) {
                  return bus;
                }

                return {
                  ...bus,
                  emptyKmPerDay: countryPrefilledEmptyValues.prefilledEmptyKm,
                  emptyHrPerDay: countryPrefilledEmptyValues.prefilledEmptyHr
                };
              })
          };
        }
      );

      return {
        ...state,
        countryPrefilledEmptyValues: {
          ...state.countryPrefilledEmptyValues,
          [countryId]: countryPrefilledEmptyValues
        },
        busScheduleValues
      };
    },
    receivedCountryDriverConceptTypesForVehicle: (
      state,
      {
        payload: {countryId, countryDriverConceptTypes}
      }: PayloadAction<{
        countryId: string;
        countryDriverConceptTypes: CountryDriverConceptType[];
      }>
    ) => {
      if (isNotNilOrEmpty(state.countryDriverConceptTypes[countryId])) {
        return state;
      }

      return {
        ...state,
        countryDriverConceptTypes: {
          ...state.countryDriverConceptTypes,
          [countryId]: countryDriverConceptTypes
        }
      };
    },
    receivedPartnerAndCountryDriverCostForVehicle: (
      state,
      {
        payload
      }: PayloadAction<{
        busPartnerId: string;
        countryId: string;
        productionCostYear: number;
        busPartnerDriverCostEntry: PartnerCostsDriverCost;
        countryDriverCostEntry: RegionalCostsDriverCost;
      }>
    ) => {
      const {
        busPartnerId,
        countryId,
        productionCostYear,
        busPartnerDriverCostEntry,
        countryDriverCostEntry
      } = payload;

      const busScheduleValues = [...(state.busScheduleValues ?? [])].map(
        busSchedule => {
          return {
            ...busSchedule,
            busScheduleProvidedValuesEntries:
              busSchedule.busScheduleProvidedValuesEntries.map(bus => {
                if (
                  bus.busPartnerId !== busPartnerId ||
                  bus.countryId !== countryId
                ) {
                  return bus;
                }

                return {
                  ...bus,
                  accommodationCostPerNight:
                    busPartnerDriverCostEntry?.accommodationCostPerNight ??
                    countryDriverCostEntry?.accommodationCostPerNight
                };
              })
          };
        }
      );

      return {
        ...state,
        busPartnerAndCountryDriverCostEntry: {
          ...state.busPartnerAndCountryDriverCostEntry,
          [busPartnerId + countryId + productionCostYear]: {
            busPartnerDriverCostEntry,
            countryDriverCostEntry
          }
        },
        busScheduleValues
      };
    },
    productionCostBaseUpdate: (
      state,
      {payload}: PayloadAction<Partial<ProductionCostState>>
    ) => {
      return {
        ...state,
        ...payload,
        lineSchedules: payload.startDate
          ? productionCostsInitialState.lineSchedules
          : state.lineSchedules
      };
    },
    receivedPlanrLines: (state, {payload}: PayloadAction<PlanrLine[]>) => {
      return {
        ...state,
        lines: payload
      };
    },
    productionCostWasCreated: (
      state,
      {payload: productionCostEntryId}: PayloadAction<string>
    ) => {
      return {
        ...state,
        id: productionCostEntryId
      };
    },
    addLineSchedule: state => {
      const lineSchedules = [...state.lineSchedules];
      lineSchedules.push(getInitLineSchedule());

      return {
        ...state,
        lineSchedules
      };
    },
    deleteLineSchedule: (
      state,
      {payload: lineScheduleKey}: PayloadAction<number>
    ) => {
      const lineSchedules = [...state.lineSchedules].filter(
        schedule => schedule.lineScheduleKey !== lineScheduleKey
      );
      const resetProductionCost: any = {};
      if (isEmpty(lineSchedules)) {
        resetProductionCost.startDate = null;
        resetProductionCost.endDate = null;
        resetProductionCost.status = null;
        resetProductionCost.costYear = null;
      }
      return {
        ...state,
        lineSchedules,
        ...resetProductionCost
      };
    },
    receivedPlanrSchedules: (
      state,
      {
        payload: {lineScheduleKey, schedules}
      }: PayloadAction<{
        lineScheduleKey: number;
        schedules: PlanrSchedule[];
      }>
    ) => {
      const lineSchedules = [...state.lineSchedules].map(schedule =>
        schedule.lineScheduleKey === lineScheduleKey
          ? {...schedule, schedules}
          : schedule
      );
      return {
        ...state,
        lineSchedules
      };
    },
    receivedPlanrBusPartners: (
      state,
      {payload: planrBusPartners}: PayloadAction<PlanrBusPartner[]>
    ) => {
      return {
        ...state,
        planrBusPartners
      };
    },
    receivedPlanrVehicles: (
      state,
      {payload: planrVehicles}: PayloadAction<PlanrVehicle[]>
    ) => {
      return {
        ...state,
        planrVehicles
      };
    },
    updateFilterType: (
      state,
      {payload: filterType}: PayloadAction<ProductionCostFilterType>
    ) => {
      return {
        ...state,
        filterType
      };
    },
    filteredPlanrBusPartnerIds: (
      state,
      {payload: filteredPlanrBusPartnerIds}: PayloadAction<string[]>
    ) => {
      return {
        ...state,
        filteredPlanrBusPartnerIds
      };
    },
    filteredPlanrVehicleIds: (
      state,
      {payload: filteredPlanrVehicleIds}: PayloadAction<string[]>
    ) => {
      return {
        ...state,
        filteredPlanrVehicleIds
      };
    },
    updateLineSchedulesSelection: (
      state,
      {
        payload
      }: PayloadAction<{
        lineScheduleKey: number;
        selection: Array<{value: string; selected: boolean}>;
      }>
    ) => {
      const lineSchedules = [...state.lineSchedules].map(lineSchedule => {
        if (lineSchedule.lineScheduleKey !== payload.lineScheduleKey) {
          return lineSchedule;
        }

        let hasSelectedLineSchedules = false;

        const schedules = [...lineSchedule.schedules].map(schedule => {
          schedule = {...schedule};
          const selection = payload.selection.find(
            selection => selection.value === schedule.uuid
          );

          if (selection && selection.selected) {
            schedule.selected = true;

            hasSelectedLineSchedules = true;
          } else {
            delete schedule.selected;
          }

          return schedule;
        });

        return {
          ...lineSchedule,
          schedules,
          isValid: hasSelectedLineSchedules
        };
      });

      return {
        ...state,
        lineSchedules
      };
    },
    toggleLineSchedule: (state, {payload}: PayloadAction<number>) => {
      const lineSchedules = [...state.lineSchedules].map(schedule =>
        schedule.lineScheduleKey === payload
          ? {...schedule, toggle: !schedule.toggle}
          : schedule
      );
      return {
        ...state,
        lineSchedules
      };
    },
    productionCostLineScheduleUpdate: (state, {payload}) => {
      const lineSchedules = [...state.lineSchedules].map(schedule =>
        schedule.lineScheduleKey === payload.lineScheduleKey
          ? {...schedule, ...payload.lineSchedule}
          : schedule
      );
      return {
        ...state,
        lineSchedules
      };
    },
    backToFirstDataImportScreen: state => {
      return {
        ...state,
        errors: {},
        busScheduleValues: null,
        calculationUserJourneyMode: CalculationUserJourneyMode.CreateCalculation
      };
    },
    backToSecondDataImportScreen: state => {
      return {
        ...state,
        calculatedProductionCosts: null,
        calculationUserJourneyMode:
          state.calculationUserJourneyMode ===
          CalculationUserJourneyMode.EditCalculation
            ? CalculationUserJourneyMode.EditCalculation
            : CalculationUserJourneyMode.EditJustCreatedCalculation
      };
    },
    receivedImportProductionCostData: (
      state,
      {payload}: PayloadAction<ProductionCostBusSchedule[]>
    ) => {
      const driverCostCalculationType = state.driverCostCalculationType;
      const busScheduleValues = payload.map(schedule => {
        const busScheduleProvidedValuesEntries =
          schedule.busScheduleProvidedValuesEntries.map(entry => {
            entry.busUtilizationFactor = convertDecimalToPercentage(
              entry?.busUtilizationFactor
            );
            return {
              ...entry,
              isValid: isVehicleValid(entry, driverCostCalculationType)
            };
          });

        return {
          ...schedule,
          busScheduleProvidedValuesEntries,
          isValid: busScheduleProvidedValuesEntries.every(
            entry => entry.isValid
          )
        };
      });

      return {
        ...state,
        busScheduleValues,
        loading: false
      };
    },
    releasedProductionCost: () => {
      return {...productionCostsInitialState};
    },
    receivedProductionCost: (
      state,
      {payload}: PayloadAction<ProductionCostState>
    ) => {
      return {
        ...state,
        ...payload,
        calculationUserJourneyMode: CalculationUserJourneyMode.EditCalculation
      };
    },
    productionCostCreationIsPending: state => {
      return {
        ...state,
        loading: true
      };
    },
    importProductionCostDataFailed: (
      state,
      {payload: productionCostCreation}: PayloadAction<any>
    ) => {
      return {
        ...state,
        loading: false,
        errors: {
          ...state.errors,
          productionCostCreation
        }
      };
    },
    removeEditScreenError: (state, {payload: errorId}: PayloadAction<any>) => {
      const filteredErrors = state.errors.productionCostCalculation.filter(
        removeError => removeError.id !== errorId
      );
      return {
        ...state,
        errors: {productionCostCalculation: filteredErrors}
      };
    },
    copyValuesToSameVehicle: (state, {payload: id}: PayloadAction<string>) => {
      const busScheduleValues = copyVehicleToBusSchedules(
        id,
        ['vehicleId'],
        [],
        state.busScheduleValues ?? []
      );
      return {
        ...state,
        busScheduleValues
      };
    },
    copyValuesToSameVehicleAndScheduleSize: (
      state,
      {payload: id}: PayloadAction<string>
    ) => {
      const busScheduleValues = copyVehicleToBusSchedules(
        id,
        ['vehicleId'],
        ['size'],
        state.busScheduleValues ?? []
      );
      return {
        ...state,
        busScheduleValues
      };
    },
    copyValuesToAll: (state, {payload: id}: PayloadAction<string>) => {
      const busScheduleValues = copyVehicleToBusSchedules(
        id,
        [],
        [],
        state.busScheduleValues ?? []
      );
      return {
        ...state,
        busScheduleValues
      };
    }
  }
});

export const {
  errorsCalculateProductionCost,
  receivedCalculateProductionCost,
  validateBusScheduleValues,
  updateLineVehicle,
  receivedProductionCostBuses,
  receivedCountryPrefilledEmptyValuesForVehicle,
  receivedCountryDriverConceptTypesForVehicle,
  receivedPartnerAndCountryDriverCostForVehicle,
  productionCostBaseUpdate,
  receivedPlanrLines,
  productionCostWasCreated,
  addLineSchedule,
  deleteLineSchedule,
  receivedPlanrSchedules,
  receivedPlanrBusPartners,
  receivedPlanrVehicles,
  updateFilterType,
  filteredPlanrBusPartnerIds,
  filteredPlanrVehicleIds,
  updateLineSchedulesSelection,
  toggleLineSchedule,
  productionCostLineScheduleUpdate,
  backToFirstDataImportScreen,
  backToSecondDataImportScreen,
  receivedImportProductionCostData,
  releasedProductionCost,
  receivedProductionCost,
  productionCostCreationIsPending,
  importProductionCostDataFailed,
  removeEditScreenError,
  copyValuesToSameVehicle,
  copyValuesToSameVehicleAndScheduleSize,
  copyValuesToAll
} = productionCostsSlice.actions;

export default productionCostsSlice.reducer;
