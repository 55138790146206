import React, {useEffect, useState} from 'react';
import {Grid, GridCol, Divider} from '@flixbus/honeycomb-react';
import TooltipInput from './TooltipInput';
import fieldInfoContent from '../../../constants/fieldInfoContent';
import {vehicleValidationSchema} from '../../../utils/validateVehicles';
import {isBlank} from 'ramda-adjunct';

const BusUtilization = ({
  lineVehicleId,
  busUtilizationFactor,
  flixStandardCostOverwrite,
  vehicleId,
  busPartnerCurrency,
  ids,
  index
}) => {
  const [isBusUtilizationDetailsOpened, setIsBusUtilizationDetailsOpened] =
    useState(false);

  useEffect(() => {
    setIsBusUtilizationDetailsOpened(!isBlank(busUtilizationFactor));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getFlixStandardCostOverwriteValidationMethod =
    busUtilizationFactorVal =>
      vehicleValidationSchema(null, {
        busUtilizationFactor: busUtilizationFactorVal
      })['flixStandardCostOverwrite'];

  return (
    <div className="bus-utilization">
      <Divider />
      <details open={isBusUtilizationDetailsOpened}>
        <summary>Vehicle utilization</summary>
        <Grid>
          <GridCol>
            <Grid>
              <GridCol key={`${index}-grid-col-5-1-1`}>
                <TooltipInput
                  id={ids.busUtilizationFactor}
                  key={`key-${ids.busUtilizationFactor}`}
                  valueKey={'busUtilizationFactor'}
                  tooltipContent={fieldInfoContent.busUtilizationFactor}
                  value={busUtilizationFactor}
                  lineVehicleId={lineVehicleId}
                  vehicleId={vehicleId}
                  name="bus-utilization-factor"
                  label="Vehicle utilization factor"
                  inlineLabel="%"
                />
              </GridCol>
              <GridCol key={`${index}-grid-col-5-1-2`}>
                <TooltipInput
                  id={ids.flixStandardCostOverwrite}
                  key={`key-${ids.flixStandardCostOverwrite}`}
                  valueKey={'flixStandardCostOverwrite'}
                  tooltipContent={fieldInfoContent.flixStandardCostOverwrite}
                  value={flixStandardCostOverwrite}
                  lineVehicleId={lineVehicleId}
                  vehicleId={vehicleId}
                  name="flix-standard-cost-overwrite"
                  label="Flix standard cost overwrite per month"
                  inlineLabel={busPartnerCurrency}
                  validationMethod={getFlixStandardCostOverwriteValidationMethod(
                    busUtilizationFactor
                  )}
                />
              </GridCol>
            </Grid>
          </GridCol>
          <GridCol>
            <div />
          </GridCol>
        </Grid>
      </details>
    </div>
  );
};

export default React.memo(BusUtilization);
