import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {Heading} from '@flixbus/honeycomb-react';
import {getPlanningPeriodDate} from '../../utils/formatePlanningPeriodDate';
import LineVehicle from './LineVehicle';
import {isNil} from 'ramda';
import ContentLoader from '../../../Common/ContentLoader';
import LinesHeader from './LinesHeader';
import ProductionCostErrorNotification from './ProductionCostErrorNotification';
import {useProductionCostErrors} from './ProductionCostErrorNotification/useProductionCostErrors';
import style from './VehicleServingSelectedLines.module.scss';

const VehicleServingSelectedLines = () => {
  const busScheduleValues = useSelector(
    state => state.productionCost.busScheduleValues
  );
  const productionCostBuses = useSelector(
    state => state.productionCost.productionCostBuses
  );
  const productionCostCalculationErrors = useProductionCostErrors();

  return (
    <div className={style.container}>
      {productionCostCalculationErrors.isErrorFound && (
        <Heading size={4} style={{color: 'red'}}>
          Errors found calculating production cost. Number of Errors:{' '}
          {productionCostCalculationErrors.size}
        </Heading>
      )}
      <ProductionCostErrorNotification
        productionCostCalculationErrors={productionCostCalculationErrors.errors}
      />

      <LinesHeader />

      <div className={style.header}>
        <h2>All vehicles serving the selected lines</h2>
      </div>

      {isNil(productionCostBuses) || isNil(busScheduleValues) ? (
        <ContentLoader />
      ) : (
        busScheduleValues.map(busScheduleValue => (
          <Fragment key={busScheduleValue.scheduleId}>
            <div className={style.vehiclesSectionLine}>
              <div>
                Line: <b>{busScheduleValue.lineNumber}</b>
              </div>
              <div>
                Schedule period:
                <b>
                  {getPlanningPeriodDate(
                    busScheduleValue.startDate,
                    busScheduleValue.endDate
                  )}
                </b>
              </div>
              <div>
                Description:
                <b>
                  {` ${busScheduleValue.name}. ${busScheduleValue.description}`}
                </b>
              </div>
            </div>
            {busScheduleValue.busScheduleProvidedValuesEntries.map(vehicle => (
              <LineVehicle
                key={`vehicle-key-${busScheduleValue.scheduleId}-${vehicle.id}`}
                index={`index-${busScheduleValue.scheduleId}-${vehicle.id}`}
                vehicle={vehicle}
                scheduleSize={busScheduleValue.size}
              />
            ))}
          </Fragment>
        ))
      )}
    </div>
  );
};

export default React.memo(VehicleServingSelectedLines);
