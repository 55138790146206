import React, {useEffect, useCallback} from 'react';
import {isEmpty, isNil} from 'ramda';
import {Select, SelectOption} from '@flixbus/honeycomb-react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {updateLineVehicle} from '../../../store/slice';
import {fetchDriverConceptTypes} from '../../../store/thunks';

const DriverConcept = ({
  id,
  countryDriverConceptTypeId,
  countryId,
  lineVehicleId,
  vehicleId
}) => {
  DriverConcept.propTypes = {
    id: PropTypes.string.isRequired,
    countryDriverConceptTypeId: PropTypes.string,
    countryId: PropTypes.string,
    vehicleId: PropTypes.string.isRequired,
    lineVehicleId: PropTypes.string.isRequired
  };

  const dispatch = useDispatch();
  const countryDriverConceptTypes = useSelector(
    state => state.productionCost.countryDriverConceptTypes
  );

  const getDriverConcepts = useCallback(() => {
    return !isNil(countryDriverConceptTypes[countryId])
      ? countryDriverConceptTypes[countryId]
      : [];
  }, [countryDriverConceptTypes, countryId]);

  const handleSelectOnDriverConceptChange = useCallback(
    countryDriverConceptTypeId => {
      dispatch(
        updateLineVehicle({
          id: lineVehicleId,
          vehicleId: vehicleId,
          countryDriverConceptTypeId: countryDriverConceptTypeId
        })
      );
    },
    [dispatch, lineVehicleId, vehicleId]
  );

  useEffect(() => {
    if (!isNil(countryId)) {
      const driverConcepts = getDriverConcepts();
      if (isEmpty(driverConcepts)) {
        dispatch(fetchDriverConceptTypes(countryId));
      }
    }
  }, [countryId, getDriverConcepts, dispatch]);

  useEffect(() => {
    if (!isNil(countryId) && isNil(countryDriverConceptTypeId)) {
      //select if only one option available
      const driverConcepts = getDriverConcepts();
      if (driverConcepts.length === 1) {
        handleSelectOnDriverConceptChange(driverConcepts[0].id);
      }
    }
  }, [
    countryDriverConceptTypeId,
    getDriverConcepts,
    handleSelectOnDriverConceptChange,
    countryId
  ]);

  const getDriverConceptOptions = () => {
    return getDriverConcepts().map(driverConceptType => (
      <SelectOption key={driverConceptType.id} value={driverConceptType.id}>
        {driverConceptType.name}
      </SelectOption>
    ));
  };

  return (
    <Select
      id={id}
      valid={!isNil(countryDriverConceptTypeId)}
      disabled={isNil(countryId)}
      label="Driver concept*"
      placeholder="Select a driver concept"
      value={countryDriverConceptTypeId || ''}
      onChange={e => handleSelectOnDriverConceptChange(e.target.value)}
    >
      {getDriverConceptOptions()}
    </Select>
  );
};

export default React.memo(DriverConcept);
