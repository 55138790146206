import {PartnerCostsDriverCost} from 'Pages/PartnerCosts/store/types';
import {
  CountryDriverConceptType,
  RegionalCostsDriverCost
} from 'Pages/RegionalCosts/store/types';

export interface ProductionCostState {
  id: string | null;
  name: string | null;
  startDate: string | null;
  endDate: string | null;
  status: ProductionCostStatus | null;
  costYear: number | null;
  lines: PlanrLine[];
  lineSchedules: PlanrLineSchedule[];
  productionCostBuses: ProductionCostBus[] | null;
  countryPrefilledEmptyValues: {
    [countryId: string]: CountryPrefilledEmptyValues;
  };
  countryDriverConceptTypes: {[countryId: string]: CountryDriverConceptType[]};
  busPartnerAndCountryDriverCostEntry: {
    [id: string]: {
      busPartnerDriverCostEntry: PartnerCostsDriverCost;
      countryDriverCostEntry: RegionalCostsDriverCost;
    };
  };
  busScheduleValues: ProductionCostBusSchedule[] | null;
  calculatedProductionCosts: CalculatedProductionCost | null;
  errors: any;
  loading: boolean;
  driverCostCalculationType: DriverCostCalculationType;
  leasingCostCalculationType: LeasingCostCalculationType;
  calculationUserJourneyMode: CalculationUserJourneyMode;
  planrBusPartners: PlanrBusPartner[];
  filteredPlanrBusPartnerIds: string[];
  planrVehicles: PlanrVehicle[];
  filteredPlanrVehicleIds: string[];
  filterType: ProductionCostFilterType;
  released: boolean | null;
}

export interface PlanrLine {
  uuid: string;
  number?: string;
  description?: string;
}

export interface PlanrLineSchedule {
  lineScheduleKey: number;
  isValid: boolean;
  toggle: boolean;
  lineNumber: string | null;
  lineUuid: string | null;
  scheduleStatus: PlanrScheduleStatus | null;
  scheduleType: PlanrScheduleType | null;
  schedules: PlanrSchedule[];
  productionCostBuses: ProductionCostBus[] | null;
}

export interface PlanrSchedule {
  uuid: string;
  lineUuid: string;
  name: string;
  startDate: string;
  endDate: string;
  status: PlanrScheduleStatus;
  type: PlanrScheduleType;
  description?: string;
  selected?: boolean;
}

export type CountryPrefilledEmptyValues = {
  prefilledEmptyHr?: number;
  prefilledEmptyKm?: number;
};

export interface ProductionCostBusSchedule {
  scheduleId: string;
  startDate: string;
  endDate: string;
  lineNumber: string;
  name?: string;
  description?: string;
  size?: string;
  busScheduleProvidedValuesEntries: ProductionCostBusScheduleProvidedValueEntry[];
  isValid: boolean;
}

export interface ProductionCostBusScheduleProvidedValueEntry {
  id: string;
  vehicleId: string;
  vehicleName?: string;
  busPartnerId: string;
  busPartnerBusTypeCostEntryId?: string;
  countryBusTypeCostEntryId?: string;
  countryId?: string;
  timeZone?: string;
  emptyKmPerDay?: number;
  emptyHrPerDay?: number;
  countryDriverConceptTypeId?: string;
  salaryChange?: number;
  accommodationsPerWeek?: number;
  accommodationCostPerNight?: number | null;
  replacementKmPerDay?: number | null;
  replacementHrPerDay?: number | null;
  additionalCostPerTrip?: number;
  totalDriverCostOverwrite?: number;
  numberOfDrivers?: number;
  driverSalary?: number;
  busUtilizationFactor?: number | null;
  flixStandardCostOverwrite?: number;
  isValid: boolean;
  currency?: string;
}

export interface CalculatedProductionCost {
  busCostPerKm?: number;
  busName: string;
  busPartnerId: string;
  busVehicleId: string;
  currency: string;
  lineId: string;
  lineNumber: string;
  lineWeightFactor: number;
  numOperatingDays: number;
  numSchedules: number;
  totalAdBlueCostPerKm?: number;
  totalCostPerKm?: number;
  totalDieselCostPerKm?: number;
  totalDriverSalaryCostPerKm?: number;
  totalLngCostPerKm?: number;
  totalOtherCostPerKm?: number;
  weeklyDaytimeWeekdayScheduleHours: number;
  weeklyDaytimeWeekendScheduleHours: number;
  weeklyNighttimeWeekdayScheduleHours: number;
  weeklyNighttimeWeekendScheduleHours: number;
  weeklyTotalKm: number;
  weeklyTotalScheduleHours: number;
  weeklyTotalWorkingHours?: number;
}

export interface PlanrBusPartner {
  busPartnerBackendId: string;
  busPartnerName?: string;
  busPartnerShortName?: string;
}

export interface PlanrVehicle {
  id: string;
  name: string;
  planrBusPartner: PlanrBusPartner;
}

export interface ProductionCostBus {
  busPartnerName?: string;
  name: string;
  vehicleId: string;
}

export enum ProductionCostStatus {
  InProduction = 'IN_PRODUCTION',
  Draft = 'DRAFT',
  Planned = 'PLANNED'
}

export enum PlanrScheduleStatus {
  Archived = 'ARCHIVED',
  ConceptChange = 'CONCEPT_CHANGE',
  Draft = 'DRAFT',
  Final = 'FINAL',
  InGeneration = 'IN_GENERATION',
  Planned = 'PLANNED',
  Published = 'PUBLISHED'
}

export enum PlanrScheduleType {
  Holiday = 'HOLIDAY',
  Regular = 'REGULAR'
}

export enum DriverCostCalculationType {
  DriverScheduleHours = 'DRIVER_SCHEDULE_HOURS',
  DriverCostOverwrite = 'DRIVER_COST_OVERWRITE',
  DriverSalary = 'DRIVER_SALARY'
}

export enum LeasingCostCalculationType {
  Standard = 'STANDARD',
  FixedSplit30To70 = 'FIXED_SPLIT_30_70',
  FixedSplit25To75 = 'FIXED_SPLIT_25_75'
}

export enum CalculationUserJourneyMode {
  CreateCalculation = 1,
  EditCalculation = 2,
  EditJustCreatedCalculation = 3
}

export enum ProductionCostFilterType {
  AllVehicles = 'ALL_VEHICLES',
  PlanrBusPartners = 'PLANR_BUS_PARTNERS',
  PlanrVehicles = 'PLANR_VEHICLES'
}
