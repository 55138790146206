import {gql} from '@apollo/client';

const CALCULATE_PRODUCTION_COST = gql`
  mutation calculateProductionCost(
    $productionCostEntryId: ID!
    $driverCostCalculationType: DriverCostCalculationType!
    $leasingCostCalculationType: LeasingCostCalculationType!
    $busScheduleProvidedValuesEntries: [ProductionCostBusScheduleProvidedValuesEntryInput]!
  ) {
    calculateProductionCost(
      productionCostEntryId: $productionCostEntryId
      driverCostCalculationType: $driverCostCalculationType
      leasingCostCalculationType: $leasingCostCalculationType
      busScheduleProvidedValuesEntries: $busScheduleProvidedValuesEntries
    ) {
      lineId
      lineNumber
      busVehicleId
      busName
      busPartnerId
      numSchedules
      numOperatingDays
      weeklyTotalKm
      weeklyDaytimeWeekdayScheduleHours
      weeklyNighttimeWeekdayScheduleHours
      weeklyDaytimeWeekendScheduleHours
      weeklyNighttimeWeekendScheduleHours
      weeklyTotalScheduleHours
      weeklyTotalWorkingHours
      lineWeightFactor
      totalDriverSalaryCostPerKm
      totalDieselCostPerKm
      totalLngCostPerKm
      totalAdBlueCostPerKm
      busCostPerKm
      totalOtherCostPerKm
      totalCostPerKm
      currency
    }
  }
`;

export default CALCULATE_PRODUCTION_COST;
